import React from "react"
import styled from "styled-components"

import Seo from "../../components/seo"
import PageWrapper from "../../styles/PageWrapper"
import Row from "../../styles/Row"

const PrivacyPolicyPageWrapper = styled(PageWrapper)`
  padding-top: 4rem;

  color: ${props => props.theme.color.primary};
`

export default function PrivacyPolicyPage() {
  return (
    <PrivacyPolicyPageWrapper>
      <Seo title="Privacy Policy" />

      <Row>
        <h1>Privacy Policy</h1>

        <p>
          Rich Products Corporation and their affiliates and subsidiaries
          (hereinafter collectively referred to as "Rich's," "us," "we," "our,"
          or "Company") have created this Privacy Policy to apply to all users
          of this website, www.freal.com and all digital assets contained or
          offered therein (collectively, our "Services"). This Privacy Policy
          describes, among other things, the types of information we collect
          from users when you use our Services, how we use it, and how you can
          access your information.
        </p>

        <p>
          This Privacy Policy is integrated into our{" "}
          <a
            href="https://frealbusinestg.wpengine.com/wp-content/uploads/2023/01/2023-Richs-Terms-Conditions-for-Site-Owners.docx"
            target="_blank"
            rel="noreferrer noopener"
          >
            Terms &amp; Conditions of Use
          </a>{" "}
          ("Terms &amp; Conditions"). By using the Services and providing us
          with Information (defined below), you agree to the practices described
          in this Privacy Policy and to the updates to these policies posted
          here from time to time.
        </p>

        <p>
          If you are a resident of California, Virginia, Colorado, Connecticut,
          Utah, or the European Union (EU), European Economic Area ("EEA"), and
          UK, you may be entitled to certain individual rights under the
          California Consumer Privacy Act of 2018 [as amended by the California
          Privacy Rights Act of 2020 ("CPRA")] (collectively, "CCPA"), Virginia
          Consumer Data Protection Act ("VCDPA"), Colorado Privacy Act ("CPA"),
          Connecticut Act Concerning Personal Data Privacy and Online Monitoring
          ("CTDPA"), Utah Consumer Privacy Act ("UCPA"), or the General Data
          Protection Regulation ("GDPR") and the UK Data Protection Act 2018
          respectively. Please see the Notice to Certain Residents of Data
          Subject Rights Section of our Privacy Policy for your rights and how
          to exercise them for users located in California, Virginia, Colorado,
          Connecticut, Utah, and the EEA and UK only.
        </p>

        <p>
          If you have any privacy or data use concerns, please contact us as set
          out below under the heading "
          <a href="#how-to-contact-us">How to Contact Us</a>." To make sure you
          stay informed of all changes, you should check these policies
          periodically. Updates will be referenced by the "Last Updated" date
          shown above.
        </p>

        <h2>I. INFORMATION WE COLLECT ABOUT YOU</h2>

        <p>
          We may collect the following categories of personal data about you
          which are described in more detail below: (A) information you provide
          to us, (B) information we may automatically collect, and (C)
          information we may receive from third parties. The information listed
          in (A)- (C) above, are detailed below, and hereinafter referred to as
          "Information."
        </p>

        <h3>A. Information You Provide to Us</h3>

        <p>
          In using our Services, you may provide us with Information, including,
          without limitation:
        </p>

        <ul>
          <li>
            Individual identifier such as name, username, birth date, and
            contact information including email address, mailing address, and
            telephone number(s);
          </li>

          <li>
            Payment and transactional information such as Services
            purchased.&nbsp; Note that we use third-party payment processors to
            facilitate your payments and do not store your payment information;
          </li>

          <li>
            Communications with us, preferences, and other Information you
            provide to us such as any messages (including via online chat
            feature), opinions and feedback that you provide to us, your user
            preferences (such as in receiving updates or marketing information),
            and other Information that you share with us when you contact us
            directly (such as for customer support services); and
          </li>

          <li>
            Additional Information as otherwise described to you at the point of
            collection or pursuant to your consent..
          </li>
        </ul>

        <h3>B. Information We May Automatically Collect About You</h3>

        <p>
          Our Services may automatically collect the following categories of
          usage and technical Information about you. This Information is used by
          Rich's for the operation of the Services, to maintain quality of the
          Services, and to provide general statistics regarding use of the
          Services. This Information may include:
        </p>

        <ul>
          <li>
            IP address, which is the number associated with the service through
            which you access the Internet, like your ISP (Internet service
            provider);
          </li>

          <li>Date and time of your visit or use of our Services;</li>

          <li>Domain server from which you are using our Services;</li>

          <li>
            Type of computer, web browsers, search engine used, operating
            system, or platform you use;
          </li>

          <li>
            Data identifying the web pages you visited prior to and after
            visiting our website or use of our Services; and
          </li>

          <li>
            Your movement and activity within the website, which is aggregated
            with other information;
          </li>

          <li>Geographic data such as country or region;</li>

          <li>
            Mobile device information, including the type of device you use,
            operating system version, and the device identifier (or "UDID"); and
          </li>

          <li>
            Mobile application identification and behavior, use, and aggregated
            usage, performance data, and where the application was downloaded
            from.
          </li>
        </ul>

        <h3>
          C. Cookies &amp; Technologies Used to Collect Information About You
        </h3>

        <p>
          We and/or certain service providers operating on our behalf may
          collect Information about your activity, or activity on devices
          associated with you over time, on our sites and applications, and
          across non-affiliated websites or online applications. We may collect
          this Information by using certain technologies, such as cookies, web
          beacons, pixels, software developer kits, third-party libraries, and
          other similar technologies. Third-party service providers,
          advertisers, and/or partners may also view, edit, or set their own
          cookies or place web beacons.
        </p>

        <ul>
          <li>
            a. <span style={{ textDecoration: "underline" }}>Cookies</span> (or
            browser cookies). Cookies are small digital files that are
            transferred to your computer or smartphone's hard drive when you
            visit a website or click on a URL. Most web browsers automatically
            accept cookies. You may refuse to accept browser cookies by
            activating the appropriate setting on your browser. However, if you
            select this setting, you may be unable to access certain parts of
            our Services. Unless you have adjusted your browser setting so that
            it will refuse cookies, our system will issue cookies when you
            direct your browser to our Services.
          </li>

          <li>
            b. <span style={{ textDecoration: "underline" }}>Web Beacons</span>.
            Website pages may contain small electronic files known as web
            beacons (also referred to as clear gifs, pixel tags, and
            single-pixel gifs) that permit us, for example, to count users who
            have visited those pages and for other related statistics (for
            example, recording the popularity of certain content and verifying
            system and server integrity). We also use these technical methods to
            analyze the traffic patterns, such as the frequency with which our
            users visit various parts of the Services. These technical methods
            may involve the transmission of Information either directly to us or
            to a third party authorized by us to collect Information on our
            behalf. Our Services may use retargeting pixels from Google,
            Facebook, and other ad networks. We may also use web beacons in HTML
            emails that we send to determine whether the recipients have opened
            those emails and/or clicked on links in those emails.
          </li>

          <li>
            c. <span style={{ textDecoration: "underline" }}>Analytics</span>.
            Analytics are tools we use, such as Google Analytics, to help
            provide us with information about traffic to our website and use of
            our Services, which Google may share with other services and
            websites who use the collected data to contextualize and personalize
            the ads of its own advertising network. Learn more about Google's
            Privacy Policy here:{" "}
            <a
              rel="noreferrer noopener"
              href="https://policies.google.com/privacy?hl=en-US"
              target="_blank"
            >
              https://policies.google.com/privacy?hl=en-US
            </a>
            . You can opt out of having your activity on our Services made
            available to Google Analytics by installing the Google Analytics
            opt-out add-on for your web browser by visiting:{" "}
            <a
              rel="noreferrer noopener"
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
            >
              https://tools.google.com/dlpage/gaoptout
            </a>{" "}
            for your web browser.
          </li>

          <li>
            d.{" "}
            <span style={{ textDecoration: "underline" }}>
              Mobile Application Technologies
            </span>
            . If you access our website and Services through a mobile device, we
            may automatically collect Information about your device, your phone
            number, and your physical location.
          </li>
        </ul>

        <p>
          Our use of online tracking technologies may be considered a "sale" or
          "sharing" under certain laws. To the extent that these online tracking
          technologies are deemed to be a "sale" or "sharing" under certain
          laws, you can opt out of these online tracking technologies by
          submitting a request via{" "}
          <a href="https://frealbusinestg.wpengine.com/contact-us/">
            Contact Us
          </a>{" "}
          or by broadcasting an opt-out preference signal, such as the Global
          Privacy Control (GPC). Please note that some features of our Services
          may not be available to you as a result of these choices.
        </p>

        <h3>D. Information We May Receive from Third Parties</h3>

        <p>
          We may collect additional Information about you from third-party
          websites, social media platforms, such as but not limited to,
          Facebook, Twitter, or Instagram ("Social Media Platforms"), and/or
          sources providing publicly available information (e.g., from the U.S.
          postal service) to help us provide services to you, help prevent
          fraud, and for marketing and advertising purposes.
        </p>

        <p>
          This Privacy Policy only applies to Information collected by our
          Services. We are not responsible for the privacy and security
          practices of those other websites or Social Media Platforms or the
          Information they may collect (which may include IP address). You
          should contact such third parties directly to determine their
          respective privacy policies. Links to any other websites or content do
          not constitute or imply an endorsement or recommendation by us of the
          linked website, Social Media Platform, and/or content.
        </p>

        <h2>II. HOW WE USE YOUR INFORMATION</h2>

        <h3>E. Use and Purpose of Processing Your Information</h3>

        <p>
          We use and process your Information for things that may include, but
          are not limited to, the following:
        </p>

        <ul>
          <li>
            To respond to your inquiries and provide you with requested
            information and other communications, including by email;
          </li>

          <li>To process your product registration request;</li>

          <li>
            To send you our newsletter and other correspondence you request;
          </li>

          <li>
            To help you locate retailer, distributor, and wholesaler locations
            near you;
          </li>

          <li>
            For general or targeted marketing and advertising purposes,
            including sending you promotional material or special offers on our
            behalf or on behalf of our marketing partners and/or their
            respective affiliates and subsidiaries and other third parties,
            provided that you have not already opted out of receiving such
            communications;
          </li>

          <li>To fulfill contracts we have with you;</li>

          <li>
            To manage, improve, and foster relationships with third-party
            service providers, including vendors, suppliers, and parents,
            affiliates, subsidiaries, and business partners;
          </li>

          <li>
            To maintain, improve, customize, or administer the Services, perform
            business analyses, or other internal purposes to improve the quality
            of our business, the Services, resolve technical problems, or
            improve security or develop other products and services;
          </li>

          <li>To comply with our Terms &amp; Conditions;</li>

          <li>
            For analytics for business purposes and business intelligence;
          </li>

          <li>
            To comply with any applicable laws and regulations and respond to
            lawful requests; and/or
          </li>

          <li>
            For any other purposes disclosed to you at the time we collect your
            Information and/or pursuant to your consent.
          </li>
        </ul>

        <p>
          We may also use Information that has been de-identified and/or
          aggregated for purposes not otherwise listed above.
        </p>

        <h3>F. Sharing or Disclosing Your Information</h3>

        <p>
          We may share and/or disclosure your Information as set forth in the
          Privacy Policy and in the following circumstances:
        </p>

        <ul>
          <li>
            <strong>Third-Party Service Providers</strong>
            <strong>.</strong> &nbsp;We may share your Information with
            third-party service providers or data processors that perform
            certain functions or services on our behalf (such as to host the
            Services, store or manage the data, perform analyses, process
            payments, provide customer service, or send communications for
            us).&nbsp; These third-party service providers will process this
            data only for purposes specified by us. &nbsp;In some instances, we
            may aggregate Information we collect so third parties do not have
            access to your identifiable Information to identify you
            individually.
          </li>

          <li>
            <strong>
              Disclosure of Information for Legal and Administrative Reasons
            </strong>
            <strong>.</strong> &nbsp;We may disclose your Information without
            notice: (i) when required to by law or to comply with a court order,
            subpoena, search warrant, or other legal process; (ii) to cooperate
            with or undertake an internal or external investigation or audit;
            (iii) to comply with legal, regulatory, or administrative
            requirements of governmental authorities (including, without
            limitation, requests from the governmental agency authorities to
            view your Information); (iv) to protect and defend the rights,
            property, or safety of us, our subsidiaries and affiliates and any
            of their officers, directors, employees, attorneys, agents,
            contractors and partners, and the website Service users; (v) to
            enforce or apply our Terms &amp; Conditions; and (vi) to verify the
            identity of the user of our Services.
          </li>

          <li>
            <strong>Business Transfers.</strong>&nbsp; Your Information may be
            transferred, sold, or otherwise conveyed to a third party
            ("Conveyances") where we: (i) merge with or are acquired by another
            business entity; (ii) sell all or substantially all of our assets;
            (iii) are adjudicated bankrupt; or (iv) are liquidated or otherwise
            reorganize.&nbsp; You agree to any and all such Conveyances of your
            Information. We may also share Information with prospective
            purchasers to evaluate the proposed transaction.
          </li>

          <li>
            <strong>
              Information Shared with our Subsidiaries, Parents, and Affiliates.
            </strong>
            &nbsp; We may share your Information with our subsidiaries and
            affiliates. If you do not want us to share your Information with our
            subsidiaries and affiliates, please contact privacy@rich.com or call
            1-877-352-0077.
          </li>

          <li>
            <strong>Aggregate and Deidentified Data. </strong>&nbsp;We may share
            general Information, aggregated data or publish information based on
            aggregated data.&nbsp; However, we will only do so in a way that
            your personal identity is protected.
          </li>

          <li>
            <strong>Online Communications.</strong> &nbsp;Any Information you
            submit in a public forum (e.g., a blog or social network) may be
            read, collected, or used by us and other participants, and could be
            used to personalize your experience. &nbsp;You are responsible for
            the Information you choose to submit in these instances.
          </li>

          <li>
            <strong>With Your Consent.</strong>&nbsp; We may share Information
            consistent with this Privacy Policy with your consent
            <strong>.</strong>
          </li>
        </ul>

        <p>
          Except as provided in this Privacy Policy, we will not sell, exchange,
          trade, or disclose your Information we have collected without your
          consent.
        </p>

        <h2>III. LINKS TO OTHER WEBSITES</h2>

        <p>
          Our Services may contain links to other websites or services that are
          not owned or controlled by us, including links to Social Media
          Platforms such as Facebook, Instagram, or YouTube, or may redirect you
          off our website away from our Services.
        </p>

        <p>
          This Privacy Policy only applies to Information collected by our
          Services. We are not responsible for the privacy and security
          practices of those other websites or Social Media Platforms or the
          Information they may collect (which may include IP address). You
          should contact such third parties directly to determine their
          respective privacy policies. Links to any other websites or content do
          not constitute or imply an endorsement or recommendation by us of the
          linked website, Social Media Platform, and/or content.
        </p>

        <h2>IV. INFORMATION SECURITY</h2>

        <p>
          We use commercially reasonable measures to provide our Services.
          However, you should assume that no data transmitted over the Internet
          or stored or maintained by us or our third-party service providers can
          be 100% secure. Therefore, although we believe the measures
          implemented by us reduce the likelihood of security problems to a
          level appropriate to the type of data involved, we do not promise or
          guarantee, and you should not expect, that your Information or private
          communications will always remain private or secure. We do not
          guarantee that your Information will not be misused by third parties.
          We are not responsible for the circumvention of any privacy settings
          or security features. You agree that we will not have any liability
          for misuse, access, acquisition, deletion, or disclosure of your
          Information.
        </p>

        <p>
          If you believe that your Information has been accessed or acquired by
          an unauthorized person, you shall promptly contact us via the{" "}
          <a href="#how-to-contact-us">How to Contact Us</a> section so that we
          can quickly take necessary measures.
        </p>

        <h2>V. DATA RETENTION</h2>

        <p>
          We will retain your Information for as long as needed to provide you
          Services, and as necessary to comply with our legal obligations,
          resolve disputes, and enforce our policies. We will retain and use
          your Information as necessary to comply with our legal obligations,
          resolve disputes, and enforce our agreements. In accordance with our
          routine record keeping, we may delete certain records that contain
          Information you have submitted to us. We are under no obligation to
          store such Information indefinitely and disclaim any liability arising
          out of, or related to, the destruction of such Information.
        </p>

        <h2>VI. YOUR CHOICES</h2>

        <ul>
          <li>
            A. Marketing Communications. If you do not want to receive marketing
            and promotional emails from us, you may click on the "unsubscribe"
            link in the email to unsubscribe and opt out of marketing email
            communications or see{" "}
            <a href="#how-to-contact-us">How to Contact Us</a> below for more
            information.
          </li>

          <li>
            B. Opting Out of Direct Marketing by Third Parties. To exercise
            choices regarding the marketing information you receive, you may
            also review the following links:
            <ul>
              <li>
                • You may opt out of tracking and receiving tailored
                advertisements on your mobile device by some mobile advertising
                companies and other similar entities by downloading the App
                Choices app at{" "}
                <a
                  href="https://www.aboutads.info/appchoices"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  www.aboutads.info/appchoices
                </a>
                .
              </li>

              <li>
                • You may opt out of receiving permissible targeted
                advertisements by using the NAI Opt-out tool available at{" "}
                <a
                  href="http://optout.networkadvertising.org/?c=1"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  http://optout.networkadvertising.org/?c=1
                </a>{" "}
                or visiting About Ads at{" "}
                <a
                  href="http://optout.aboutads.info"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  http://optout.aboutads.info
                </a>
                .
              </li>

              <li>
                • You can opt out of having your activity on our Services made
                available to Google Analytics by installing the Google Analytics
                opt-out add-on for your web browser by visiting:{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://tools.google.com/dlpage/gaoptout
                </a>{" "}
                for your web browser.
              </li>
            </ul>
          </li>
        </ul>

        <h2>VII. NOTICE TO CERTAIN RESIDENTS OF DATA SUBJECT RIGHTS</h2>

        <h2>A.&nbsp;&nbsp; NOTICE TO CALIFORNIA RESIDENTS</h2>

        <p>
          To the extent any California data privacy law applies to the
          collection of your Information, this supplemental section of our
          Privacy Policy outlines the rights that California residents may have,
          and how they can exercise those rights. This notice applies solely to
          California residents. We provide the supplemental selection below to
          comply with the California Consumer Privacy Act [as amended by the
          California Privacy Rights Act (referred to collectively hereinafter as
          CCPA)] and any terms defined in the CCPA have the same meaning when
          used below.
        </p>

        <h3>Your Rights under CCPA</h3>

        <ul>
          <li>
            <strong>Right to Know and Access Specific Information.</strong>{" "}
            &nbsp;You have the right to request that we disclose certain
            information to you about our collection and use of your Information
            over the past twelve (12) months.&nbsp; Once we receive and confirm
            a verifiable consumer request from you, we will disclose to you, to
            the extent permitted by law:
          </li>

          <li>
            The categories of Information we collected about you, and whether we
            sell or share your Information to third parties.
          </li>

          <li>The specific pieces of Information we hold about you.</li>

          <li>
            The categories of personal Information sold within the last 12
            (twelve) months.
          </li>

          <li>
            The categories of sources from which Information about you is
            collected.
          </li>

          <li>
            Our business or commercial purpose for collecting, selling, or
            sharing your Information.
          </li>

          <li>
            The categories of third parties with whom your Information is sold,
            shared, or disclosed for a business purpose.
          </li>
        </ul>

        <p>
          You have the right to request that the Information described above be
          provided to you in a portable and readily usable format, to the extent
          technically feasible ("data portability").
        </p>

        <ul>
          <li>
            You have the right to request that we delete the Information that we
            collected from you, subject to certain exceptions.&nbsp; To the
            extent that we can delete your Information, once we receive and
            confirm your verifiable consumer request, we will delete (and direct
            our service providers to delete) your Information, unless an
            exception applies.
          </li>

          <li>
            <strong>Right to Correct Inaccurate Information.</strong> &nbsp;To
            the extent that we may maintain inaccurate personal Information, you
            have the right to request that we correct such inaccurate personal
            Information taking into account the nature of the personal
            Information and the purposes of the processing of the personal
            Information.&nbsp; Once we receive and verify your verifiable
            consumer request, we will use commercially reasonable efforts to
            correct your personal Information.
          </li>

          <li>
            <strong>
              Sale and Sharing of Personal Information and the Right to Opt Out.
            </strong>
            &nbsp; You have the right to opt out of the processing of your
            Information for the following purposes:
          </li>

          <li>Sale of your Information.</li>

          <li>
            Sharing of your Information for cross-context behavioral
            advertising.
          </li>
        </ul>

        <p>
          The use of online tracking technologies may be considered a "sale" or
          "sharing" under California law. &nbsp;To the extent that these online
          tracking technologies are deemed to be a "sale" or "sharing" under
          California law, you may opt out of these online tracking technologies
          by submitting a request via{" "}
          <a href="https://frealbusinestg.wpengine.com/contact-us/">
            Contact Us
          </a>{" "}
          or by broadcasting an opt-out preference signal, such as the Global
          Privacy Control (GPC).
        </p>

        <ul>
          <li>
            <strong>
              Right to Limit Use and Disclosure of Sensitive Personal
              Information.
            </strong>{" "}
            &nbsp;You have the right to request that we limit the ways we use
            and disclose your sensitive personal Information (as defined by
            CCPA) to uses which are necessary for us to perform the Services, or
            deliver the goods reasonably expected by you, or and as authorized
            by law.&nbsp;
          </li>

          <li>
            <strong>Right to Non-Discrimination.</strong> &nbsp;You have a right
            to not be discriminated against in the Services or quality of
            Services you receive from us for exercising your rights. &nbsp;We
            may not, and will not, treat you differently because of your data
            subject request activity.&nbsp; As a result of your data subject
            request activity, we may not and will not deny goods or Services to
            you, charge different rates for goods or Services, provide a
            different level quality of goods or Services, or suggest that we
            would treat you differently because of your data subject request
            activity.<strong>&nbsp;</strong>
          </li>

          <li>
            <strong>Right to Disclosure of Direct Marketers.</strong> &nbsp;You
            have a right to the categories and names/addresses of third parties
            that have received personal Information for their direct marketing
            purposes upon simple request, and free of charge.&nbsp;
          </li>
        </ul>

        <p>
          You may make an authenticated consumer request exercising your Right
          to Know and Access Specific Information including Right to Know what
          Personal Information is being Sold or Shared or under the CCPA twice
          within a twelve (12) month period. To exercise the rights described
          above, see the Exercising Your Rights section below.
        </p>

        <h3>Authorized Agent</h3>

        <p>
          You may use an authorized agent to submit verifiable consumer requests
          on your behalf provided that the authorized agent is a natural person
          or a business entity that you have authorized to act on your behalf.
          If you use an authorized agent, we will require: (1) proof of written
          permission for the authorized agent to make requests on your behalf,
          and identity verification from you; or (2) proof of power of attorney
          pursuant to California Probate Code sections 4000 to 4665. We may deny
          a request from an authorized agent that does not submit proper
          verification proof.
        </p>

        <h3>Notice of Financial Incentive</h3>

        <p>
          As an incentive for providing us with your Information, you may
          receive a financial benefit in the form of an email coupon, discount,
          rebate, free sample, promotions, or other similar reward that will be
          sent to you. This discount may constitute a financial incentive under
          the California Consumer Privacy Act ("Financial Incentive"). The
          categories of personal Information required for us to provide the
          Financial Incentives include personal identifiers such as full name
          and contact Information including email address as well as personal
          and demographic Information you choose to provide.
        </p>

        <p>
          To offer these discounts, we may track your personal Information, such
          as purchase history and other demographic data. You have the right to
          withdraw from the Financial Incentive at any time by submitting a
          request to <a href="mailto:privacy@rich.com">privacy@rich.com</a>.
          Please note that if you request deletion of part or all your personal
          Information, that could affect your ability to qualify for the
          discount.
        </p>

        <h3>Additional Information</h3>

        <p>
          To the extent permitted by applicable law, we may charge a reasonable
          fee to comply with your request.
        </p>

        <h2>B. NOTICE TO VIRGINIA RESIDENTS</h2>

        <p>
          To the extent any Virginia data privacy law applies to the collection
          of your Information, this supplemental section of our Privacy Policy
          outlines the individual rights guaranteed to Virginia residents and
          how to exercise those rights and applies solely to Virginia residents.
          We provide the supplemental selection below to comply with the
          Virginia Consumer Data Protection Act ("VCDPA") and any terms defined
          in the VCDPA have the same meaning when used below.
        </p>

        <h3>Your Rights under VCDPA</h3>

        <p>
          Subject to certain exceptions you may be entitled to the following
          rights:
        </p>

        <ul>
          <li>
            Right to Access &amp; Data Portability. You have the right to
            request that we disclose certain information to you about our
            collection and use of your Information at any time. Once we receive
            and confirm an authenticated consumer request from you, we will,
            subject to certain exceptions:
            <ul>
              <li>
                Disclose whether or not we are processing your Information.
              </li>

              <li>Provide you with access to your Information.</li>
            </ul>
          </li>

          <li>
            Where the processing is carried out by automated means, and subject
            to certain exceptions, you have the right to request and obtain a
            copy of your Information that you previously provided to us in a
            portable and, to the extent technically feasible, readily usable
            format that allows you to transmit the Information to another data
            controller without hindrance.
          </li>

          <li>
            Right to Correct Inaccurate Information. To the extent that we may
            maintain inaccurate Information, you have the right to request that
            we correct such inaccurate Information taking into account the
            nature of the personal Information and the purposes of the
            processing of the personal Information. Once we receive and verify
            your authenticated consumer request, we will use commercially
            reasonable efforts to correct your Information.
          </li>

          <li>
            Right to Delete. You have the right to request that we delete
            certain of your Information provided by or obtained about you. To
            the extent that we can delete your Information, once we receive and
            confirm your authenticated consumer request, we will delete (and
            direct our service providers to delete) your Information, subject to
            certain exceptions.
          </li>

          <li>
            • Sale of Personal Information, Targeted Advertising, Profiling, and
            the Right to Opt Out. You have the right to opt out of the
            processing of your Information for the following purposes:
            <ul>
              <li>Targeted advertising.</li>

              <li>Sale of your Information.</li>

              <li>
                Profiling in furtherance of decisions that produce legal or
                similarly significant effects concerning you.
              </li>
            </ul>
          </li>

          <li>
            • Right to Appeal. You have the right to appeal our denial of any
            request you make under this section. To exercise your right to
            appeal, please submit an appeal request via the information in the{" "}
            <a href="#how-to-contact-us">How To Contact Us</a> section below.
            Within sixty (60) days of receipt of your appeal, we will inform you
            in writing of any action taken or not taken in response to your
            appeal, including a written explanation of the reasons for the
            decisions. If we deny your appeal, you may contact the Virginia
            Office of the Attorney General by:
            <ul>
              <li>
                Contacting Online:{" "}
                <a
                  href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint
                </a>
              </li>

              <li>
                Calling:
                <ul>
                  <li>
                    If calling from Virginia, call the Consumer Protection
                    Hotline at 1-800-552-9963.
                  </li>

                  <li>
                    If calling from the Richmond area or from outside Virginia,
                    call the Consumer Protection Hotline at 1-804-786-2042.
                  </li>
                </ul>
              </li>

              <li>
                Right to Non-Discrimination. You have a right to not be
                discriminated against in the Services or quality of Services you
                receive from us for exercising your rights. We will not
                discriminate against you for exercising any of your rights in
                this section including denying goods or Services, charging
                different prices or rates for goods or Services, or providing a
                different level of quality of goods and Services. However, we
                may offer a different price, rate, level, quality, or selection
                of goods or Services, including offering goods or Services for
                no fee, if you have exercised your right to opt out or the offer
                is related to your voluntary participation in a bona fide
                loyalty, rewards, premium features, discounts, or club card
                program.
              </li>

              <li>
                You may make an authenticated consumer request under the VCDPA
                twice within a twelve (12) month period. To exercise the rights
                described above, see the Exercising Your Rights section below.
              </li>
            </ul>
          </li>
        </ul>

        <h3>Additional Information</h3>

        <p>
          To the extent permitted by applicable law, we may charge a reasonable
          fee to comply with your request.
        </p>

        <h2>C. NOTICE TO COLORADO RESIDENTS</h2>

        <p>
          To the extent any Colorado data privacy law applies to the collection
          of your Information, this supplemental section of our Privacy Policy
          outlines the individual rights guaranteed to Colorado residents and
          how to exercise those rights and applies solely to Colorado residents.
          We provide the supplemental selection below to comply with the
          Colorado Privacy Act ("CPA") and any terms defined in the CPA have the
          same meaning when used below.
        </p>

        <h3>Your Rights under CPA</h3>

        <p>
          Subject to certain exceptions you may be entitled to the following
          rights:
        </p>

        <ul>
          <li>
            Right to Access &amp; Data Portability. You have the right to
            request that we disclose certain information to you about our
            collection and use of your Information at any time. Once we receive
            and confirm an authenticated consumer request from you, we will,
            subject to certain exceptions:
            <ul>
              <li>
                Disclose whether or not we are processing your Information.
              </li>

              <li>
                Provide you with access to your Information where we process it.
              </li>
            </ul>
          </li>

          <li>
            Where exercising your right to access, you have the right to request
            and obtain a copy of your Information that you previously provided
            to us in a portable and, to the extent technically feasible, readily
            usable format that allows you to transmit the Information to another
            data controller without hindrance ("data portability").
          </li>

          <li>
            Right to Correct Inaccurate Information. To the extent that we may
            maintain inaccurate personal Information, you have the right to
            request that we correct such inaccurate personal Information taking
            into account the nature of the personal Information and the purposes
            of the processing of the personal Information. Once we receive and
            verify your authenticated consumer request, we will use commercially
            reasonable efforts to correct your personal Information.
          </li>

          <li>
            Right to Delete. You have the right to request that we delete
            certain of your Information provided by or obtained about you. To
            the extent that we can delete your Information, once we receive and
            confirm your authenticated consumer request, we will delete your
            Information, subject to certain exceptions.
          </li>

          <li>
            Sale of Personal Information, Targeted Advertising, Profiling, and
            the Right to Opt Out. You have the right to opt out of the
            processing of your Information for the following purposes:
            <ul>
              <li>Targeted advertising.</li>

              <li>Sale of your Information.</li>

              <li>
                Profiling in furtherance of decisions that produce legal or
                similarly significant effects concerning you.
              </li>
            </ul>
          </li>

          <li>
            Right to Appeal. You have the right to appeal our denial of any
            request you make under this section. To exercise your right to
            appeal, please submit an appeal request via the information in the{" "}
            <a href="#how-to-contact-us">How To Contact Us</a> section below.
            Within forty-five (45) days of receipt of your appeal, we will
            inform you in writing of any action taken or not taken in response
            to your appeal, including a written explanation of the reasons for
            the decisions. If you have concerns regarding the results of your
            appeal, you may contact the Colorado Office of the Attorney General
            by:
            <ul>
              <li>
                Contacting Online:{" "}
                <a
                  rel="noreferrer noopener"
                  href="https://complaints.coag.gov/s/contact-us"
                  target="_blank"
                >
                  https://complaints.coag.gov/s/contact-us
                </a>
              </li>

              <li>Calling: (720) 508-6000</li>
            </ul>
          </li>
        </ul>

        <p>
          You may make an authenticated consumer request free of charge under
          the CPA once within a twelve (12) month period. We reserve the right
          to charge a reasonable fee for a second or subsequent request within
          the same twelve (12) month period. To exercise the rights described
          above, see the Exercising Your Rights section below.
        </p>

        <h3>Authorized Agent</h3>

        <p>
          You may use an authorized agent to submit verifiable consumer requests
          on your behalf. An authorized agent is a natural person or a business
          entity that you have authorized to act on your behalf. If you use an
          authorized agent, we will require: (1) proof of written permission for
          the authorized agent to make requests on your behalf, and identity
          verification from you; or (2) proof of power of attorney sent to
          privacy@rich.com. We may deny a request from an authorized agent that
          does not submit proper verification proof.
        </p>

        <h3>Notice of Financial Incentive</h3>

        <p>
          As an incentive for providing us with your Information, you may
          receive a financial benefit as part of our bona fide loyalty program
          in the form of an email coupon, discount, rebate, free sample,
          promotions, or other similar reward that will be sent to you. This
          discount may constitute a financial incentive under the Consumer
          Privacy Act ("Financial Incentive"). The categories of personal
          Information required for us to provide the Financial Incentives
          include personal identifiers such as full name and contact information
          including email address as well as personal and demographic
          information you choose to provide.
        </p>

        <p>
          To offer these discounts, we may track your personal Information, such
          as purchase history and other demographic data. You have the right to
          withdraw from the Financial Incentive at any time by submitting a
          request to <a href="mailto:privacy@rich.com">privacy@rich.com</a>.
          Please note that if you request deletion of part or all your personal
          Information, that could affect your ability to qualify for the
          discount.
        </p>

        <h3>Additional Information</h3>

        <p>
          To the extent permitted by applicable law, we may charge a reasonable
          fee to comply with your request.
        </p>

        <h2>D. NOTICE TO CONNECTICUT RESIDENTS</h2>

        <p>
          To the extent any Connecticut data privacy law applies to the
          collection of your Information, this supplemental section of our
          Privacy Policy outlines the individual rights guaranteed to
          Connecticut residents and how to exercise those rights and applies
          solely to Connecticut residents. We provide the supplemental selection
          below to comply with the Connecticut Act Concerning Personal Data
          Privacy and Online Monitoring ("CTDPA") and any terms defined in the
          CTDPA have the same meaning when used below.
        </p>

        <h3>Your Rights under CTDPA</h3>

        <p>
          Subject to certain exceptions you may be entitled to the following
          rights:
        </p>

        <ul>
          <li>
            Right to Access &amp; Data Portability. You have the right to
            request that we disclose certain information to you about our
            collection and use of your Information at any time. Once we receive
            and confirm an authenticated consumer request from you, we will,
            subject to certain exceptions:
            <ul>
              <li>
                Disclose whether or not we are processing your Information.
              </li>

              <li>
                Provide you with access to your Information where we process it.
              </li>
            </ul>
          </li>

          <li>
            Where the processing is carried out by automated means, and subject
            to certain exceptions, you have the right to request and obtain a
            copy of your Information that you previously provided to us in a
            portable and, to the extent technically feasible, readily usable
            format that allows you to transmit the Information to another data
            controller without hindrance.
          </li>

          <li>
            Right to Correct Inaccurate Information. To the extent that we may
            maintain inaccurate personal Information, you have the right to
            request that we correct such inaccurate personal Information taking
            into account the nature of the personal Information and the purposes
            of the processing of the personal Information. Once we receive and
            verify your authenticated consumer request, we will use commercially
            reasonable efforts to correct your personal Information.
          </li>

          <li>
            Right to Delete. You have the right to request that we delete
            certain of your Information provided by or obtained about you. To
            the extent that we can delete your Information, once we receive and
            confirm your authenticated consumer request, we will delete (and
            direct our service providers to delete) your Information, subject to
            certain exceptions.
          </li>

          <li>
            Sale of Personal Information, Targeted Advertising, Profiling, and
            the Right to Opt Out. You have the right to opt out of the
            processing of your Information for the following purposes:
            <ul>
              <li>Targeted advertising.</li>

              <li>Sale of your Information.</li>

              <li>
                Profiling in furtherance of decisions that produce legal or
                similarly significant effects concerning you.
              </li>
            </ul>
          </li>

          <li>
            • Right to Non-Discrimination. You have a right to not be
            discriminated against in the Services or quality of Services you
            receive from us for exercising your rights. We will not discriminate
            against you for exercising any of your rights in this section
            including denying goods or Services, charging different prices or
            rates for goods or Services, or providing a different level of
            quality of goods and Services. However, we may offer a different
            price, rate, level, quality, or selection of goods or Services,
            including offering goods or services for no fee, if you have
            exercised your right to opt out or the offer is related to your
            voluntary participation in a bona fide loyalty, rewards, premium
            features, discounts, or club card program.
          </li>

          <li>
            Right to an Appeal. You have the right to appeal our denial of any
            request you make under this Section. To exercise your right to
            appeal, please submit appeals request to us by either:
            <ul>
              <li>Emailing us at privacy@rich.com</li>

              <li>Calling us at 1-877-352-0077</li>
            </ul>
          </li>

          <li>
            Within sixty (60) days of receipt of your appeal, we will inform you
            in writing of any action taken or not taken in response to your
            appeal, including a written explanation of the reasons for the
            decisions. If we deny your appeal, you may contact the Connecticut
            Office of the Attorney General by:
            <ul>
              <li>
                Contacting Online:{" "}
                <a
                  href="https://www.dir.ct.gov/ag/complaint/e-complaint.aspx?CheckJavaScript=1"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.dir.ct.gov/ag/complaint/e-complaint.aspx?CheckJavaScript=1
                </a>
              </li>

              <li>Calling: The Consumer Assistance Unit at 860-808-5420</li>
            </ul>
          </li>
        </ul>

        <p>
          You may make an authenticated consumer request under the CTDPA once
          within a twelve (12) month period. To exercise the rights described
          above, see the Exercising Your Rights section below.
        </p>

        <p>
          If we cannot authenticate your request, we may request more
          information or may be unable to fulfill your request.
        </p>

        <h3>Authorized Agent</h3>

        <p>
          You may use an authorized agent to submit verifiable consumer requests
          on your behalf. An authorized agent is a natural person or a business
          entity that you have authorized to act on your behalf. If you use an
          authorized agent, we will require: (1) proof of written permission for
          the authorized agent to make requests on your behalf, including
          through technology means, and identity verification from you; or (2)
          proof of power of attorney. We may deny a request from an authorized
          agent that does not submit proper verification proof.
        </p>

        <h3>Additional Information</h3>

        <p>
          To the extent permitted by applicable law, we may charge a reasonable
          fee to comply with your request.
        </p>

        <h2>E. NOTICE TO UTAH RESIDENTS</h2>

        <p>
          To the extent any Utah data privacy law applies to the collection of
          your Information, this supplemental section of our Privacy Policy
          outlines the individual rights guaranteed to Utah residents and how to
          exercise those rights and applies solely to Utah residents. We provide
          the supplemental selection below to comply with the Utah Consumer
          Privacy Act ("UCPA") and any terms defined in the UCPA have the same
          meaning when used below.
        </p>

        <h3>Your Rights under UCPA</h3>

        <p>
          Subject to certain exceptions you may be entitled to the following
          rights:
        </p>

        <ul>
          <li>
            Right to Access &amp; Data Portability. You may have the right to
            request that we disclose certain information to you about our
            collection and use of your Information at any time. Once we receive
            and confirm an authenticated consumer request from you, we will,
            subject to certain exceptions:
            <ul>
              <li>
                Disclose whether or not we are processing your Information
              </li>

              <li>
                Provide you with access to your Information where we process it
              </li>
            </ul>
          </li>

          <li>
            Where the processing is carried out by automated means, and subject
            to certain exceptions, you have the right to request and obtain a
            copy of your Information that you previously provided to us in a
            portable and, to the extent technically feasible, readily usable
            format that allows you to transmit the Information to another data
            controller without hindrance ("data portability").
          </li>

          <li>
            Right to Delete. You have the right to request that we delete
            certain of your Information provided by or obtained about you. To
            the extent that we can delete your Information, once we receive and
            confirm your authenticated consumer request, we will delete (and
            direct our service providers to delete) your Information, subject to
            certain exceptions.
          </li>

          <li>
            Right to Non-Discrimination. We will not discriminate against you
            for exercising any of your rights in this section including denying
            goods or Services, charging different prices or rates for goods or
            Services, or providing a different level of quality of goods and
            services.
          </li>

          <li>
            Sale of your Personal Information, Targeted Advertising, and Right
            to Opt Out. You have the right to opt out of the processing of your
            Information for the following purposes:
            <ul>
              <li>Targeted advertising</li>

              <li>Sale of your Information</li>
            </ul>
          </li>
        </ul>

        <p>
          You may make an authenticated consumer request free of charge under
          the UCPA once within a twelve (12) month period. We reserve the right
          to charge a reasonable fee for a second or subsequent request within
          the same twelve (12) month period. To exercise the rights described
          above, see the Exercising Your Rights section below.
        </p>

        <h3>Additional Information</h3>

        <p>
          To the extent permitted by applicable law, we reserve the right to
          charge a reasonable fee for any subsequent requests within the same
          twelve (12) month period.
        </p>

        <h2>
          F. NOTICE TO INDIVIDUALS IN THE EUROPEAN ECONOMIC AREA AND THE UK
        </h2>

        <p>
          This section applies only to individuals coming to our Services from
          within the European Union (EU), the European Economic Area (EEA), and
          the UK, and only if we collect through the Services any Information
          from you that is considered "Personal Data," as defined in the General
          Data Protection Regulation (GDPR) and the UK Data Protection Act 2018.
        </p>

        <p>
          Personal Data includes any information relating to an identified or
          identifiable natural person, who could be identified either directly
          or indirectly by reference to an identifier such as a name, an
          identification number, location data, an online identifier, or to one
          (1) or more factors specific to the physical, physiological, genetic,
          mental, economic, cultural, or social identity of that natural person
          (which may include some or all your Information as defined in this
          Privacy Policy).
        </p>

        <h3>
          Identity and Contact Details of Controller and EU Representative
        </h3>

        <p>
          Unless otherwise stated, we are the Data Controller for the
          Information we process.
        </p>

        <p>
          <strong>Contact Details:</strong>
        </p>

        <p>
          <em>Email:</em> <a href="mailto:privacy@rich.com">privacy@rich.com</a>
        </p>

        <p>
          <em>Call us:</em> +44 03300605174
        </p>

        <p>
          <em>Write us:</em>
          <br />
          Rich Andover Realty Limited
          <br />
          Unit 5 Pioneer Road
          <br />
          Andover Business Park
          <br />
          Andover, GB SP11 8EZ
        </p>

        <h3>Your Data Protection Rights</h3>

        <p>
          To the extent the GDPR and Data Protection Act 2018 apply, and we hold
          your Information in our capacity as a Data Controller as defined under
          those laws, you may request that we:
        </p>

        <ul>
          <li>Restrict the way that we process and share your Information;</li>

          <li>Transfer your Information to a third party;</li>

          <li>Provide you with access to your Information;</li>

          <li>
            Remove your Information if no longer necessary for the purposes
            collected;
          </li>

          <li>
            Update your Information so it is correct and not out of date; and/or
          </li>

          <li>Object to our processing of your Information.</li>
        </ul>

        <p>
          You may also revoke your consent for processing of your Information.
          If you wish to object to the use and processing of your Information or
          withdraw consent to this Privacy Policy, you can contact us in the
          following ways:
        </p>

        <p>
          <em>Email:</em> <a href="mailto:privacy@rich.com">privacy@rich.com</a>
        </p>

        <p>
          <em>Call us:</em> +44 03300605174
        </p>

        <p>
          <em>Write us:</em>
          <br />
          Rich Andover Realty Limited
          <br />
          Unit 5 Pioneer Road
          <br />
          Andover Business Park
          <br />
          Andover, GB SP11 8EZ
        </p>

        <p>
          The requests above will be considered and responded to in the
          time-period stated by applicable law. Note, certain Information may be
          exempt from such requests. We may require additional Information from
          you to confirm your identity in responding to such requests.
          <br />
          You have the right to lodge a complaint with the supervisory
          authorities applicable to you and your situation, although we invite
          you to contact us with any concern as we would be happy to try and
          resolve it directly. Please contact us at:
        </p>

        <p>
          <em>Email:</em> <a href="mailto:privacy@rich.com">privacy@rich.com</a>
        </p>

        <p>
          <em>Call us:</em> +44 03300605174
        </p>

        <p>
          <em>Write us:</em>
          <br />
          Rich Andover Realty Limited
          <br />
          Unit 5 Pioneer Road
          <br />
          Andover Business Park
          <br />
          Andover, GB SP11 8EZ
        </p>

        <h3>Lawful Basis for Processing Your Information</h3>

        <p>
          The lawful basis for our processing of your Personal Data will depend
          on the purposes of the processing. For most Personal Data processing
          activities covered by this Privacy Policy, the lawful basis is that
          the processing is necessary for our legitimate business interests.
          Where we process Personal Data in relation to a contract, or a
          potential contract, with you, the lawful basis is that the processing
          is necessary for the performance of our contract with you or to take
          steps at your request prior to entering into a contract. If we are
          required to share Personal Data with law enforcement agencies or other
          governmental bodies, we do so on the basis that we are under a legal
          obligation to do so. We will also use consent as the legal basis where
          we deem appropriate or to the extent required by applicable law, for
          example, before we collect precise location data from your mobile
          device.
        </p>

        <p>
          Depending on what Personal Data we collect from you and how we collect
          it, we may also rely on various grounds for processing your Personal
          Data, including the following reasons:
        </p>

        <ul>
          <li>
            Processing on the basis of legitimate business interests. When we
            process Personal Data on the basis that the processing is necessary
            for our legitimate business interests, such interests include: (i)
            providing, improving, and promoting our Services; (ii) communicating
            with current and potential customers, other business partners, and
            their individual points of contact; (iii) managing our relationships
            with our customers and other business partners, and their individual
            points of contact; (iv) other business development purposes; (v)
            sharing information within the Company, as well as with service
            providers and other third parties; and (vi) maintaining the safety
            and security of our products, Services, and employees, including
            fraud protection.
          </li>

          <li>
            Processing on the basis of performance of a contract. Examples of
            situations in which we process Personal Data as necessary for
            performance of a contract include e-commerce transactions in which
            you purchase a service from us.
          </li>

          <li>
            Processing on the basis of consent. Examples of processing
            activities for which we may use consent as its legal basis include:
            (i) collecting and processing precise location information from your
            mobile device; (ii) sending promotional emails when consent is
            required under applicable law; and (iii) processing Personal Data on
            Company Services through cookies and similar technologies when
            consent is required by applicable law.
          </li>

          <li>
            Processing because we are under a legal obligation to do so.
            Examples of situations in which we must processes Personal Data to
            comply with our legal obligations include: (i) providing your
            Personal Data to law enforcement agencies and other governmental
            bodies when required by applicable laws; (ii) retaining business
            records required to be retained by applicable laws; and (iii)
            complying with court orders or other legal process.
          </li>
        </ul>

        <p>
          If the processing of your Information is based on your consent, the
          GDPR and Data Protection Act 2018 also allow users the right to
          access, revoke, or modify your consent at any time. Please see the{" "}
          <a href="#how-to-contact-us">How to Contact Us</a> section, below, to
          review or modify your consents.
        </p>

        <h3>Consent to Transfer</h3>

        <p>
          We are operated in the United States, and we may use service providers
          based in the United States to operate our business and our
          relationship with you. Please be aware that Information, including
          your Personal Data, that we collect will be transferred to, stored,
          and processed in the United States, a jurisdiction in which the
          privacy laws may not be as comprehensive as those in the country where
          you reside and/or are a citizen. We maintain measures to address the
          transfer of your Personal Data between our group companies and between
          us and our third-party providers in accordance with applicable data
          protection laws and regulations.
        </p>

        <h3>Retention</h3>

        <p>
          We will retain your Information for as long as needed for the purposes
          described in this Privacy Policy. More specifically, the time we
          maintain your Information depends on the following factors:
        </p>

        <ul>
          <li>
            Whether we need the Information to provide the Services. We will
            maintain any data needed to provide you with the Services, such as
            contact information and payment or transaction information, for as
            long as needed for us to provide you with the Services, respond to
            your questions and requests, and/or administer your account (if
            applicable).
          </li>

          <li>
            Whether we need the Information to comply with our legal
            obligations. We may have legal obligations to maintain your
            Information where a legal or regulatory body may ask for it in the
            future, for example in response to a data subject request or
            complaint. This information may include contact information and
            location information.
          </li>

          <li>
            Whether we need the Information for a legitimate business interest.
            We may store Information like contact information, cookies, and
            location information in order to perform analytics, troubleshoot
            errors, or improve our Services. In any event, we delete the
            Information when it is no longer needed for our legitimate interest.
          </li>
        </ul>

        <p>
          Regardless of our reason for retaining your Information, we delete all
          Information in accordance with our routine record keeping policies.
        </p>

        <h3>VIII. EXERCISING YOUR DATA Subject RIGHTS</h3>

        <p>
          To exercise any of the rights described above, please submit a
          verifiable consumer request to us via the methods described below. The
          verifiable consumer request must:
        </p>

        <ul>
          <li>
            Provide sufficient information that allows us to reasonably verify
            you are the person about whom we collected Information, or an
            authorized representative; and
          </li>

          <li>
            Describe your request with sufficient detail that allows us to
            properly understand, evaluate, and respond to it.
          </li>
        </ul>

        <p>
          To help protect your privacy and maintain security, if you request
          access to or deletion of your Information, we will take steps and may
          require you to provide certain information to verify your identity
          before granting you access to your Information or complying with your
          request. In addition, if you ask us to provide you with specific
          pieces of Information, we may require you to sign a declaration under
          penalty of perjury that you are the consumer whose Information is the
          subject of the request. Only you or your authorized agent may make a
          verifiable consumer request related to your Information. If you
          designate an authorized agent to make a request on your behalf, we may
          require you to provide the authorized agent written permission to do
          so and to verify your own identity directly with us (as described
          above). You may also make a verifiable consumer request on behalf of
          your minor child.
        </p>

        <p>
          <em>Email:</em> <a href="mailto:privacy@rich.com">privacy@rich.com</a>
        </p>

        <p>
          <em>Call us:</em> 1-877-352-0077
        </p>

        <p>
          <em>Write us:</em>
          <br />
          Rich Products Corporation
          <br />
          One Robert Rich Way
          <br />
          Buffalo, New York 14213
        </p>

        <h3>IX. GEOGRAPHIC LOCATION OF DATA STORAGE AND PROCESSING</h3>

        <p>
          Our Services collect Information and processes and stores that
          Information in databases located in the United States. As such, we may
          store and process Information on servers located outside of the
          country where you originally deposited the data. If you are visiting
          the Services from a country outside the United States, you should be
          aware that you may transfer personally identifiable Information about
          yourself to the United States, and that the data protection laws of
          the United States may not be as comprehensive as those in your own
          country. By visiting our Services and submitting Information, you
          consent to the transfer of such Information to the United States.
        </p>

        <h3>X. CHILDREN'S INFORMATION</h3>

        <p>
          The Services are intended only for users over the age of sixteen (16).
          If we become aware that a user is under sixteen (16) (or a higher age
          threshold where applicable) and has provided us with Information, we
          will take steps to comply with any applicable legal requirement to
          remove such Information. Contact us if you believe that we have
          mistakenly or unintentionally collected Information from a child under
          the age of sixteen (16).
        </p>

        <h3>XI. CHANGES TO THIS PRIVACY POLICY</h3>

        <p>
          We reserve the right to change, modify, or amend this Privacy Policy
          at any time to reflect changes in our products and service offerings,
          accommodate new technologies, regulatory requirements, or other
          purposes. If we modify our Privacy Policy, we will update the "Last
          Updated" date below and such changes will be effective upon posting.
          It is your obligation to check our current Privacy Policy for any
          changes.
        </p>

        <h3 id="how-to-contact-us">XII. HOW TO CONTACT US</h3>

        <p>
          If you have any questions about this Privacy Policy or the Information
          we have collected about you, please contact us at the following:
        </p>

        <p>
          <em>Email:</em> <a href="mailto:privacy@rich.com">privacy@rich.com</a>
        </p>

        <p>
          <em>Call us:</em> 1-877-352-0077
        </p>

        <p>
          <em>Write us:</em>
          <br />
          Rich Products Corporation
          <br />
          One Robert Rich Way
          <br />
          Buffalo, New York 14213
        </p>

        <p>Last Updated: January 1, 2023</p>
      </Row>
    </PrivacyPolicyPageWrapper>
  )
}
